<template>
  <section class="sectionpage">
    <div class="container text-center">
      <div class="notfoundpage-title">
        <ICON_NotFound :size="icon_size" />
        <h4 class="pt-3">Oops!</h4>
        <p class="pt-3">
          We can’t find the page <i>{{ path }}</i> you are looking for
        </p>
        <a href="../" class="pt-3"><Button :text="buttontText" /></a>
      </div>
    </div>
  </section>
</template>
<script>
import ICON_NotFound from "@/assets/icons/Error404.vue";
import Button from "@/components/Button.vue";
export default {
  components: {
    ICON_NotFound,
    Button,
  },
  data() {
    return {
      buttontText: "Back to home",
      icon_size: 24,
      path: window.location.pathname,
    };
  },
};
</script>
<style scoped>
.sectionpage {
  padding: 130px 40px 30px 40px;
}
.social404page a {
  display: inline-block;
  color: #000;
  padding: 8px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.backtohome {
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}
.backtohome:hover {
  background: #0880e8;
  color: #fff;
}
.notfoundpage-title svg {
  width: 100%;
}
@media only screen and (min-width: 0px) and (max-width: 998px) {
  .sectionpage {
    padding: 40px !important;
  }
}
</style>
